* {
  box-sizing: border-box;
}

body {
  color: #7f8c8d;
  font-size: 13px;
  line-height: 1.7em;
}

h1, h2, h3, h4, h5, h6, label {
  color: #34495e;
}

.pure-img-responsive {
  max-width: 100%;
  height: auto;
}

.l-box {
  padding: 1em;
}

.l-box-lrg {
  border-bottom: 1px solid #0000001a;
  padding: 2em;
}

.is-center {
  text-align: center;
}

.pure-form label {
  margin: 1em 0 0;
  font-size: 100%;
  font-weight: bold;
}

.pure-form input[type] {
  box-shadow: none;
  border: 2px solid #ddd;
  width: 100%;
  margin-bottom: 1em;
  font-size: 100%;
}

.pure-button {
  color: #fff;
  background-color: #1f8dd6;
  border-radius: 5px;
  padding: .5em 2em;
}

a.pure-button-primary {
  color: #1f8dd6;
  background: #fff;
  border-radius: 5px;
  font-size: 120%;
}

.home-menu {
  text-align: center;
  background: #2d3e50;
  padding: .5em;
  box-shadow: 0 1px 1px #0000001a;
}

.pure-menu.pure-menu-fixed {
  z-index: 4;
  border-bottom: none;
}

.home-menu .pure-menu-heading {
  color: #fff;
  font-size: 120%;
  font-weight: 400;
}

.home-menu .pure-menu-selected a {
  color: #fff;
}

.home-menu a {
  color: #6fbef3;
}

.home-menu li a:hover, .home-menu li a:focus {
  color: #aecfe5;
  background: none;
  border: none;
}

.splash-container {
  z-index: 1;
  background: #1f8dd6;
  width: 100%;
  height: 88%;
  top: 0;
  left: 0;
  overflow: hidden;
  position: fixed !important;
}

.splash {
  text-align: center;
  text-transform: uppercase;
  width: 80%;
  height: 50%;
  margin: auto;
  position: absolute;
  inset: 100px 0 0;
}

.splash-head {
  color: #fff;
  border: 3px solid #fff;
  border-radius: 5px;
  padding: 1em 1.6em;
  font-size: 20px;
  font-weight: 100;
  line-height: 1em;
}

.splash-subhead {
  color: #fff;
  letter-spacing: .05em;
  opacity: .8;
}

.content-wrapper {
  z-index: 2;
  background: #fff;
  width: 100%;
  min-height: 12%;
  position: absolute;
  top: 87%;
}

.content {
  padding: 1em 1em 3em;
}

.content-head {
  text-transform: uppercase;
  letter-spacing: .1em;
  margin: 2em 0 1em;
  font-weight: 400;
}

.content-head-ribbon {
  color: #fff;
}

.content-subhead {
  color: #1f8dd6;
}

.content-subhead i {
  margin-right: 7px;
}

.ribbon {
  color: #aaa;
  background: #2d3e50;
}

.footer {
  background: #111;
  width: 100%;
  position: fixed;
  bottom: 0;
}

@media (width >= 48em) {
  body {
    font-size: 16px;
  }

  .home-menu {
    text-align: left;
  }

  .home-menu ul {
    float: right;
  }

  .splash {
    width: 50%;
    height: 50%;
  }

  .splash-head {
    font-size: 250%;
  }

  .l-box-lrg {
    border: none;
  }
}

@media (width >= 78em) {
  .splash-head {
    font-size: 300%;
  }
}
/*# sourceMappingURL=index.10aac87b.css.map */
